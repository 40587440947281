.pricing {
  background: linear-gradient(to bottom, #f2f2f2, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pricing h3 {
  font-size: 35px;
  font-weight: lighter;
  margin: 80px;
  align-items: flex-start;
}

.pricing-container {
  align-items: center;
  min-height: 100vh;
}

.plans-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: auto;
}

.toggle-container {
  width: 100%;
  margin: 10px;
}

/* Responsive view (less than or equal to 768px) */
@media screen and (max-width: 768px) {
  .pricing {
    padding-left: 0;
  }

  .pricing-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .plans-container {
    width: 100%;
  }

  .pricing h3 {
    font-size: 20px;
    margin: 40px;
  }
}