.document-form {
  height: 100vh;
  width: 100%;
  background-color: #f2f2f2;
}

.pdf-frame {
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .pdf-frame {
    width: 100%;
  }
}