.column {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Mobile layout - adjust as needed */
@media (max-width: 768px) {
  .column {
    max-width: 100%;
  }
}

