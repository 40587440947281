.status-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.modal-content {
  border-radius: 10px;
}

.modal-header {
  background-color: #f0f0f0;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}

.modal-title {
  color: #333;
}

.modal-body {
  padding: 20px;
  text-align: center;
}

p {
  margin-top: 20px;
}

.modal-footer {
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.status-icon-success,
.status-icon-failure {
  display: block;
  margin: 25px;
}

.status-icon-success {
  color: green;
  font-size: 150px;
}

.status-icon-failure {
  color: red;
  font-size: 150px;
}