.toggle-slider-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  cursor: pointer;
  margin: auto;
}

.toggle-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.toggle-slider-thumb {
  position: absolute;
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggle-slider-bar.on {
  background-color: #2196F3;
}

.toggle-slider-bar.on .toggle-slider-thumb {
  transform: translateX(26px);
}
