.nav-item {
  list-style: none;
}

.nav-link {
  text-decoration: none;
  color: #333;
  transition: color 0.2s;
}

.nav-link.active {
  color: #007bff;
}

.nav-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item-text {
  font-size: 16px;
}
