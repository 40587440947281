.selected-options {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0;
}

.selected-option {
  background-color: #e9e9e9;
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  margin: 5px;
}

.selected-label {
  margin-left: 10px;
}

.remove-option {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-icon {
  color: #e9645f;
  font-size: 18px;
}