/* Generic form container */
.form-container {
  margin: 10px;
}

.form-container-categories {
  margin: 10px;
  min-width: 500px;
  max-width: 500px;
}

.form-container-categories.disabled .form-column {
  opacity: 0.5;
  pointer-events: none;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

/* Generic section title */
.section-title {
  font-size: 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e3e4e6;
  padding-bottom: 5px;
}

/* Generic form row */
.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Generic form column */
.form-column {
  flex: 1;
  margin-right: 10px;
}


.form-column-disable {
  flex: 1;
  margin-right: 10px;
  opacity: 0.5; /* Example styling for disabled column */
}

/* Generic form label */
.form-label {
  display: block;
  font-weight: bold;
  font-size: 15px;
  color: #333; /* You can change the label color as needed */
  text-align: left; /* Align labels to the left */
}

/* Generic form input */
.form-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

/* Styling for disabled input fields */
.form-input[disabled] {
  background-color: #f2f2f2;
}

@media (max-width: 600px) {
  .form-container-categories {
    margin: 10px;
    min-width: 0;
  }

  .form-row {
    flex-wrap: unset;
  }

  .form-column {
    font-size: 14px;
  }
}
