.document-table-container {
  width: 100%;
}

/* Responsive view (less than or equal to 768px) */
@media screen and (max-width: 768px) {
  .document-table-container.table-responsive {
    overflow-x: auto;
  }

  .document-table {
    table-layout: fixed;
  }

  .document-table th,
  .document-table td {
    white-space: nowrap;
  }
}
