.custom-alert {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.custom-alert h1 {
  margin: 20px;
  font-size: 24px;
  color: #333;
}

.custom-alert p {
  font-size: 16px;
  color: #343a40;
}

.custom-alert-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}

.confirm-button, .cancel-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;
}

.confirm-button {
  background-color: #dc3545;
  color: white;
}

.cancel-button {
  background-color: darkgrey;
  color: white;
}

.confirm-button:hover {
  background-color: #c82333;
}

.cancel-button:hover {
  background-color: dimgrey;
}

/* Media queries for larger screens */
@media (min-width: 768px) {
  .custom-alert-buttons {
    flex-direction: row;
    justify-content: space-around;
  }

  .confirm-button, .cancel-button {
    margin: 0 10px;
  }
}