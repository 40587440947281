.login-form-container {
  background: linear-gradient(to bottom, #f2f2f2, #fff);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form {
  background-color: #fff;
  max-width: 400px;
  width: 90%;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #283347;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: #dc3545;
  margin-top: 25px;
  font-size: 15px;
}

.forgot-password {
  font-size: 13px;
  font-weight: bold;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
}

.form-group span {
  font-size: 13px;
}

.forgot-password:focus {
  outline: none;
}

.validation-container {
  align-items: flex-start;
  padding-bottom: 150px;
  text-align: left;
}

.register-form-column {
  flex: 1;
  display: flex;
  justify-content: center;
}

.validation-points {
  display: flex;
  flex-direction: column;
}

.point {
  display: flex;
  align-items: center;
  margin-top: 2px;
  padding: 6px;
}

.point.green {
  font-size: 12px;
}

.point.red {
  font-size: 12px;
}

.point.red svg {
  color: darkorange;
  font-size: 20px;
  margin-right: 10px;
}

.point.green svg {
  color: #28a745;
  font-size: 20px;
  margin-right: 10px;
}

@media (max-width: 1048px) {
  .login-form-container {
    flex-direction: column;
    align-items: center;

  }

  .register-form-column {
    width: 100%;
    margin: 10px 0 0 0;
    justify-content: center !important;
  }

  .validation-container {
    padding-bottom: 0;
    margin: 20px;
  }

  .validation-container .validation-points .point.green {
    display: none;
  }
}
