.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.toggle-switch-container {
  align-items: center;
  justify-content: center;
  width: 250px;
}

.toggle-label {
  text-align: center;
}

.column {
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center the data-grid in responsive view */
  margin-left: 0;
}