/* Regular view (larger than 768px) */
.dashboard {
  padding-left: 40px;
  background: linear-gradient(to bottom, #f2f2f2, #fff);
}

.dashboard h1 {
  font-size: 20px;
  font-weight: bold;
  margin: 40px 10px 10px;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 830px;
}

.column {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.data-grid-and-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 100vh;
  margin-left: 10px;
}

/* Add some space between the DataGrid and LegislationSummary */
.data-grid {
  margin-right: 20px;
  align-items: flex-start; /* Align the DataGrid to the top */
}

/* Responsive view (less than or equal to 768px) */
@media screen and (max-width: 768px) {
  .dashboard {
    padding-left: 0;
  }

  .dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dashboard h1 {
    margin: 30px 10px; /* Adjust heading margin for responsive view */
  }

  .column {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center the data-grid in responsive view */
    margin-left: 0;
  }

  .data-grid-and-summary {
    max-width: 94%;
    min-width: unset;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally on small screens */
    text-align: center; /* Center text on small screens */
  }

  .data-grid {
    margin: 10px 0 30px;
    align-items: flex-start;
  }
}
