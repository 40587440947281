.sidebar {
  width: 210px;
  height: 100vh;
  position: fixed;
  left: -155px;
  background: linear-gradient(to right, #f6f6f7, #dcdee1);
  transition: left 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 52px;
}

.sidebar.open {
  left: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
}

.toggle-button {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #e3e4e6;
  transition: left 0.3s ease;
}

.person {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 15px;
  position: relative;
  /*background: linear-gradient(to bottom, #d4d6da, #f5f5f5);*/
  min-height: 150px;
}

.person-image {
  width: 80px;
  height: 80px;
}

.person-name {
  font-size: 16px;
  margin-top: 15px;
  min-height: 25px;
  color: #444444;
}

.person-name.hidden {
  opacity: 0;
}

.menu-options.hidden {
  opacity: 100;
  margin-left: 250px;
}

.menu-options {
  padding: 0;
  flex-direction: column;
  display: flex;
  font-size: 17px;
}

.menu-options li {
  height: 45px;
  color: #444;
  cursor: pointer;
  align-items: center;
  display: flex;
  transition: background-color 0.2s;
}

.menu-options li a {
  width: 100%;
  height: 100%;
  margin-left: 45px;
  align-items: center;
  display: flex;
  text-decoration: none;
  color: inherit;
}

.menu-options li:hover {
  background-color: #f0f0f0;
}

.menu-options li.selected {
  background: linear-gradient(to left, #535c6c, #9499a3);
  color: #ffffff;
}

.menu-icon {
  margin-right: 15px;
}

.menu-icon.hidden {
  margin-left: 130px;
}

.content {
  flex: 1;
  padding-left: 210px;
  transition: margin-left 0.3s ease;
  padding-top: 50px;
}

/* Add additional styles when the sidebar is closed */
.content.content-full-page {
  margin-left: -155px; /* Move the content to the left when the sidebar is closed */
}

.upgrade-plan {
  text-align: center;
  margin-top: 150px;
}

.upgrade-plan.hidden {
  opacity: 0;
}

/* Responsive styles for small screens */
@media (max-width: 768px) {
  .toggle-button {
    left: 10px;
  }

  .sidebar {
    z-index: 1000;
    width: 200px;
    left: -200px;
  }

  .person {
    padding: 10px;
  }

  .menu-options li {
    padding: 8px 0;
  }

  .menu-options li a {
    padding-left: 0;
  }

  .menu-icon.hidden {
    margin-left: 0;
  }

  .content {
    flex: 1;
    padding-left: 0;
    transition: margin-left 0.3s ease;
  }

  .content.content-full-page {
    margin-left: 0;
  }
}
