
.box {
  flex: 1;
  min-width: 180px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
  color: #e3a72b;
  justify-content: center;
}

/* Box title styles */
.box h2 {
  font-size: 18px;
  margin: 0;
  padding-bottom: 10px;
  color: #333;
}

/* Box content styles */
.box-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box p {
  font-size: 20px;
  margin: 10px;
  color: #666;
}

/* Hover effect - adjust as needed */
.box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

@media screen and (max-width: 768px) {
  .box {
    padding: 16px;
    min-width: 100px;
  }

  .box h2 {
    font-size: 16px;
  }

  .box p {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .box {
    padding: 20px;
  }

  .box h2 {
    font-size: 14px;
  }

  .box p {
    font-size: 14px;
  }
}