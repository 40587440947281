.nav-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nav-button {
  width: 100%; /* Set a fixed width for each button */
  margin-bottom: 10px; /* Add margin between buttons */
  text-align: left; /* Align button text to the left */
}
