.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.btn-primary {
  background-color: #283347 !important;
  color: #e3e4e6 !important;
  outline: none !important;
  font-weight: bold !important;
}

.btn-secondary {
  background-color: #e3e4e6 !important;
  color: #283347 !important;
  outline: none !important;
  font-weight: bold !important;
}

.btn-primary:hover {
  background-color: #e3e4e6 !important;
  color: #283347 !important;
}

.btn-secondary:hover {
  background-color: #283347 !important;
  color: #e3e4e6 !important;
}

.full-button {
  padding: 10px 20px; /* You can adjust the padding as needed */
  font-size: 16px; /* You can adjust the font size as needed */
  border: none;
  cursor: pointer;
  width: 100%; /* Set the width to 100% to match the input field's width */
  margin-bottom: 10px; /* Add some spacing between the FullButton and other elements */
}

.full-button.primary {
  background-color: #283347 !important;
  color: #e3e4e6 !important;
}

.full-button.primary:disabled {
  background-color: #e3e4e6 !important;
  color: #283347 !important;
  cursor: not-allowed;
}

.full-button.secondary {
  background-color: #e3e4e6 !important;
  color: #283347 !important;
}

.full-button.nav-primary {
  background-color: #7a808c !important;
  color: #f5f5f5 !important;
  text-align: start;
}

.full-button.nav-secondary {
  background-color: transparent;
  color: #283347 !important;
  text-align: start;
  border-left: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

.full-button.nav-cancel {
  background-color: #dc3545 !important;
  color: #f5f5f5 !important;
  text-align: center;
}

.full-button.primary:hover {
  background-color: #e3e4e6 !important;
  color: #283347 !important;
}

.full-button.secondary:hover {
  background-color: #283347 !important;
  color: #e3e4e6 !important;
}

.full-button.nav-secondary:hover {
  background-color: #e9e9e9 !important;
}

.full-button.nav-cancel:hover {
  background-color: #e3e4e6 !important;
  color: #dc3545 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

