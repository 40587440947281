.login-form-container {
  background: linear-gradient(to bottom, #f2f2f2, #fff);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: #fff;
  max-width: 400px;
  width: 90%;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 150px;
}

.form-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #283347;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

/* Styles for the error message */
.error-message {
  color: #dc3545;
  margin-top: 25px;
  font-size: 15px;
}

.forgot-password {
  font-size: 13px;
  font-weight: bold;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
}

.form-group span {
   font-size: 13px; /* You can adjust the font size as needed */
 }

.forgot-password:focus {
  outline: none;
}