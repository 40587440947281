.data-grid-container {
  width: 100%;
  height: 330px;
}

.MuiDataGrid-columnHeaders {
  background-color: #f5f5f5 !important;
}

.Mui-selected {
  background-color: #f5f5f5 !important;
}

.MuiDataGrid-row:hover {
  background-color: #f5f5f5 !important;
}

.heading-title {
  font-size: 19px;
  text-align: start;
  margin: 20px 0 20px 0;
  font-weight: normal;
}

/* Responsive styling */
@media (max-width: 600px) {
  .data-grid-container {
    overflow-x: auto;
  }

  .heading-title {
    text-align: center;
  }
}
