.home-container {
  background-image: url("../images/home_bg_lighter.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.home-content {
  text-align: center;
  width: 55%;
  flex-direction: column;
  margin-top: 20vh;
}

.home-content h1 {
  font-size: 55px;
  color: #283347;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

html, body {
  margin: 0;
  padding: 0;
}

.homepage {
  width: 100%;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  padding: 40px;
  background-color: #fff;
}

.section.reverse {
  flex-direction: row-reverse;
  background-color: #f8f8f8;
}

.section-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.section-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.section-image img {
  max-width: 100%;
  height: auto;
}

.text-content {
  max-width: 600px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.section h2 {
  margin: 10px 0 60px;
  font-size: 45px;
  color: #283347;
  text-align: left;
  position: relative;
}

.section h2::after {
  content: ""; /* Added pseudo-element */
  position: absolute;
  bottom: -15px; /* Adjust the distance below the heading */
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #e3a72b; /* Adjust the color of the underline */
}

.section p {
  color: #666;
  font-size: 1.1em;
  line-height: 1.6;
  text-align: left;
}

@media (max-width: 768px) {
  .home-content {
    width: 100%;
    margin-top: 10vh;
  }

  .home-content h1 {
    font-size: 28px; /* Decrease the font size for heading 1 on smaller screens */
  }

  .section {
    padding: 20px;
    flex-direction: column; /* Change back to column for smaller screens */
    height: auto;
  }

  .section-content {
    padding: 30px 20px 0 20px;
  }

  .section.reverse {
    flex-direction: column; /* Change back to column for smaller screens */
  }

  .section h2 {
    font-size: 28px;
  }

  .section-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
