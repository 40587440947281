.profile-form-container {
  padding: 10px;
  border-radius: 5px;
  max-width: 600px;
  min-width: 600px;
  margin: 0 auto;
}

.nav-buttons {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.nav-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.nav-buttons button.active {
  background-color: #007bff;
  color: #fff;
}

.edit-profile-form {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.form-column {
  flex: 1;
}

.form-column:last-child {
  margin-right: 0;
}

.form-column label {
  font-weight: bold;
}

.form-column input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.email-field {
  margin-bottom: 15px;
}

.email-field label {
  font-weight: bold;
}

.email-field input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.notifications-section {
  margin-bottom: 20px;
}

.notifications-section label {
  font-weight: bold;
}

.form-group {
  text-align: center;
}

.person-icon {
  text-align: center;
}

.person-icon img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.name-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.name-field {
  flex: 1;
  margin-right: 10px;
}

.name-field:last-child {
  margin-right: 0;
}

/* Add Media Query for Responsive Layout */
@media (max-width: 600px) {
  .profile-form-container {
    max-width: 100%; /* Allow the container to adjust to the screen width */
    min-width: unset; /* Remove the minimum width */
  }

  .edit-profile-form {
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .form-title {
    font-size: 20px; /* Reduce font size for smaller screens */
  }

  .form-column {
    flex: 1;
    margin-right: 0; /* Remove margin-right for all columns on small screens */
  }

  .email-field input {
    background-color: #fff; /* Allow input field to be editable on small screens */
    cursor: text; /* Change cursor to text on small screens */
  }

  .name-row {
    flex-direction: column; /* Stack name fields vertically on small screens */
    align-items: flex-start; /* Adjust alignment for stacked name fields */
  }

  .name-field {
    flex: 1;
    margin-right: 0; /* Remove margin-right for all name fields on small screens */
    margin-bottom: 10px; /* Add spacing between stacked name fields */
  }
}

