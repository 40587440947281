/* TopBar.css */
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  background: linear-gradient(to bottom, #283347, #3e4759);
  box-shadow: none;
}

.sign-out-button {
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #e3e4e6;
}

.sign-out-button {
  margin-right: 10px;
}

/* Responsive styles for small screens */
@media (max-width: 768px) {
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    background: #283347;
    box-shadow: none;
  }
}