.subscription-form {
  padding: 0 12px;
  width: 100%;
}

.subscription-form-header {
  font-weight: bold;
  text-align: left;
}

.subscription-form-description {
  margin-bottom: 24px;
  text-align: left;
}

.subscription-form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.subscription-form-column {
  flex: 1;
}

.pricing-plan {
  height: 100%;
}

.pricing-plan-content {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pricing-plan-content.current{
  border-radius: 6px;
  background-color: #535c6c;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pricing-plan-header {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
}

.pricing-plan-description {
  margin-bottom: 12px;
  font-weight: normal;
}

.pricing-plan-divider {
  border-top: 2px solid #ddd;
  margin: 12px 0;
}

.pricing-plan-price {
  align-items: center;
  text-align: center;
}

.pricing-plan-price-value {
  font-weight: bold;
  font-size: 30px;
}

.pricing-plan-price-unit {
  margin-left: 8px;
  font-size: 12px;
}

.pricing-plan-feature {
  margin: 12px auto;
  text-align: left;
  height: 90px;
  display: flex;
  flex-direction: column;
}

.pricing-plan-feature-text {
  font-size: 14px;
}

.pricing-plan-feature-options {
  font-size: 12px;
  font-weight: normal;
}

.pricing-plan-button {
  margin-top: 10px;
}

.pricing-plan-icon {
  color: green;
  font-size: 16px;
  margin-right: 5px;
}
