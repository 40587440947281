/* Base Styles */
.profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(to bottom, #f2f2f2, #fff);
  min-height: 100vh
}

.profile h1 {
  font-size: 20px;
  font-weight: bold;
  padding-left: 40px;
  margin: 40px 10px 10px;
}

.profile h2 {
  font-size: 18px;
  font-weight: bold;
  text-align: start;
  margin: 0 0 10px;
}

.profile h4 {
  font-size: 13px;
  font-weight: normal;
  text-align: start;
  margin: 0 0 10px;
}

.profile-container {
  display: flex;
  width: 100%;
}

.nav-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.nav-buttons-container-mobile {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.profile-content,
.edit-profile-content {
  border-radius: 5px;
  margin-bottom: 20px;
}

.forms-container {
  display: flex;
  justify-content: space-between;
}

.profile-form-container {
  flex: 1;
  padding: 20px;
  border-radius: 5px;
  margin: 0 10px;
}

.edit-profile-content form {
  max-width: 400px;
  margin: 0 auto;
}

.person-icon {
  text-align: center;
  margin-bottom: 20px;
}

.person-icon svg {
  width: 80px;
  height: 80px;
}

.profile-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-top: 20px;
  text-align: center;
}

.button-container {
  text-align: center; /* Align the button horizontally in the center */
}

.edit-icon-container {
  text-align: right;
  margin-bottom: 20px;
}

.edit-icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.edit-icon {
  width: 24px;
  height: 24px;
  color: #007bff;
}

.error-message {
  color: #dc3545;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .profile {
    max-width: 100%;
    min-width: unset;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally on small screens */
    text-align: center; /* Center text on small screens */
  }

  .profile h1 {
    font-size: 20px;
    text-align: center;
    padding: 0;
  }

  .person-icon svg {
    width: 60px;
    height: 60px;
  }

  .form-title {
    font-size: 20px;
  }

  .nav-buttons-container-mobile {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .nav-buttons-container {
    display: none;
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    padding: 0 !important;
  }

  .nav-button {
    margin: 10px;
  }

  .profile-container {
    display: unset;
  }
}