/* Styles for the form group container */
.custom-form-group {
  margin-bottom: 25px; /* Increase the margin to create more space between fields */
}

/* Styles for the label */
.custom-form-group label {
  display: block;
  font-size: 15px;
  color: #283347;
  margin-bottom: 5px;
  text-align: left;
}

/* Styles for the input field container */
.input-container {
  position: relative; /* Add relative positioning */
}

/* Styles for the input field */
.custom-form-control {
  width: 100%; /* Change the width value to make the input wider */
  padding: 12px;
  font-size: 13px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Styles for the input field on focus */
.custom-form-control:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

/* Optional: Styles for the input label */
.input-label {
  display: block;
  text-align: left;
}

/* Styles for the toggle password icon */
.toggle-password {
  position: absolute;
  top: 50%;
  right: 20px; /* Adjust the right distance as needed */
  transform: translateY(-50%);
  cursor: pointer;
}