.main-header {
  background: linear-gradient(to bottom, #283347, #3e4759);
}

.main-header-container {
  padding: 0 10px !important;
}

.nav-link {
  position: relative;
  color: #f2f2f2;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.5s;
}

.nav-link:hover {
  color: #f0f1f3;
}

.nav-link .btn-primary {
  background-color: #283347;
  color: #e3e4e6;
  outline: none;
  font-weight: bold;
}

.nav-link .btn-secondary {
  background-color: #e3e4e6;
  color: #283347;
  outline: none;
  font-weight: bold;
}

.nav-link .btn-primary:hover {
  background-color: #e3e4e6;
  color: #283347;
}

.nav-link .btn-secondary:hover {
  background-color: #283347;
  color: #e3e4e6;
}

.button-container .btn-secondary {
  margin-left: 10px;
}

.nav-item-content {
  display: flex;
  align-items: center;
}

.nav-item-text {
  margin-right: 0.5rem;
  color: #e3e4e6;
}

.nav-item-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5px;
  background-color: #cdd4da;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.5s;
}

.nav-link.active .nav-item-underline {
  transform: scaleX(1);
}

.formal-text {
  font-family: "Calibri Light", sans-serif;
  font-size: 25px;
  padding-left: 13px;
  padding-top: 3px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 2px;
}

/* Media query for smaller screens (e.g., mobile) */
@media (max-width: 768px) {
  .main-header-container {
    display: flex;
    justify-content: center;
  }

  .navbar-nav {
    margin-left: auto;
  }

  .formal-text {
    font-size: 22px;
    letter-spacing: 1px;
  }
}
