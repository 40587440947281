.footer {
  background-color: #282c34;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  flex: 1;
  min-width: 250px;
  margin: 20px 0;
}

.footer-section h2 {
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 14px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 5px 0;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.footer-section ul li a:hover {
  color: #e3a72b;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-icons a {
  color: white;
  margin: 0 10px;
  font-size: 20px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #e3a72b;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding: 10px 0;
  font-size: 14px;
}
